import React, { useCallback } from "react";

import dayjs, { Dayjs } from "dayjs";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  weekendsOnWorkDays,
  workDaysOnWeekends,
} from "@constants/calendar";
import { memoizedDayColor } from "@shared/calendar/monthCalendar/utils";
import { saveDate } from "@store/screenDay";
import { themeSelector } from "@store/theme";
import { Colors } from "@theme/colors";
import { TextFont, ArrowSvg } from "@ui";
import { calendar } from "@utils";

const daysOfWeekDefault = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

export const Header = ({ startDate, selectedDay }: any) => {
  const dispatch = useDispatch();
  const { extra } = themeSelector();

  const daysOfWeek = calendar.getDaysOfWeek(selectedDay) as unknown as Record<string, Dayjs>;

  const isWeekendOnWorkDay = (date: string) => weekendsOnWorkDays.includes(date);

  const isWeekend = (day: string, daysOfWeek: any) => {
    const weekDay = dayjs(daysOfWeek[day]).day();
    const isWeekendDay = weekDay === 0 || weekDay === 6;
    const dateFormatted = dayjs(daysOfWeek[day]).format("YYYY-MM-DD");
    const isHoliday = isWeekendOnWorkDay(dateFormatted);
    return isWeekendDay || isHoliday;
  };

  const dayNameColor = useCallback((day: string, daysOfWeek: Record<string, Dayjs>) => {
    if (isWeekend(day, daysOfWeek)) {
      const dateFormatted = dayjs(daysOfWeek[day]).format("YYYY-MM-DD");

      return workDaysOnWeekends.includes(dateFormatted) ? Colors.LIGHT.placeholder.main : Colors.LIGHT.red;
    }

    return Colors.LIGHT.placeholder.main;
  }, []);

  const changeWeek = (day: Dayjs) => {
    const weekDay = day.weekday();
    const dayCurrentWeek = dayjs().weekday(weekDay);

    if (dayCurrentWeek.isSame(day, "date")) {
      dispatch(saveDate(dayjs()));
    } else {
      dispatch(saveDate(day.weekday(0)));
    }
  };

  return (
    <ContentWrap>
      <BlockDaysOfWeek>
        {daysOfWeekDefault.map((day, index) => (
          <DayWrap key={index}>
            <TextFont color={dayNameColor(day, daysOfWeek)}>{day.charAt(0)}</TextFont>
          </DayWrap>
        ))}
      </BlockDaysOfWeek>
      <BlockDaysOfWeek>
        {daysOfWeekDefault.map((day, index) => {
          const numberDay = dayjs(daysOfWeek[day]).date();
          const numberMonth = dayjs(daysOfWeek[day]).month();
          const numberYear = dayjs(daysOfWeek[day]).year();
          const isToday =
            numberDay === dayjs(startDate).date() && numberMonth === dayjs(startDate).month() && numberYear === dayjs(startDate).year();

          const isSelectedDay =
            numberDay === dayjs(selectedDay).date() &&
            numberMonth === dayjs(selectedDay).month() &&
            numberYear === dayjs(selectedDay).year();

          const weekDay = dayjs(daysOfWeek[day]).day();
          const isNotCurrentMonth = numberMonth !== dayjs(selectedDay).month();
          const dayForEvents = dayjs(daysOfWeek[day]).format("YYYY-MM-DD");

          const textColor = memoizedDayColor(
            weekDay,
            isSelectedDay,
            isToday,
            isNotCurrentMonth,
            dayForEvents,
            extra === "ASSISTANT" ? Colors.ASSISTANT : Colors.LIGHT,
          );

          return (
            <Cell isAssistantTheme={extra === "ASSISTANT"} isSelected={isSelectedDay} isToday={isToday} key={index}>
              <button
                onClick={() => {
                  dispatch(saveDate(daysOfWeek[day]));
                }}
              >
                <TextFont color={isToday || isSelectedDay ? Colors.LIGHT.white : textColor} key={index}>
                  {numberDay}
                </TextFont>
              </button>
            </Cell>
          );
        })}
      </BlockDaysOfWeek>
      <ButtonArrow onClick={() => changeWeek(selectedDay.locale("ru").add(7, "day").locale("ru"))} right={-24}>
        <ArrowSvg type="right" fill={Colors.LIGHT.grey500} width={16} height={16} />
      </ButtonArrow>
      <ButtonArrow left={-24} onClick={() => changeWeek(selectedDay.locale("ru").subtract(7, "day").locale("ru"))}>
        <ArrowSvg type="left" fill={Colors.LIGHT.grey500} width={16} height={16} />
      </ButtonArrow>
    </ContentWrap>
  );
};

const ContentWrap = styled.section`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;
const BlockDaysOfWeek = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const DayWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;

const Cell = styled.div<{ isAssistantTheme: boolean; isToday: boolean; isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;

  ${(props) =>
    props.isToday &&
    `
    background-color: ${props.isAssistantTheme ? Colors.ASSISTANT.green : Colors.LIGHT.background.green};
  `}

  ${(props) =>
    props.isSelected &&
    `
    background-color: ${props.isAssistantTheme ? Colors.ASSISTANT.background.green : Colors.LIGHT.grey500};
  `}
`;
const ButtonArrow = styled.button<{ right?: number; left?: number }>`
  position: absolute;
  top: 30px;

  ${(props) =>
    props.right &&
    `
    right: ${props.right}px;
  `}
  ${(props) =>
    props.left &&
    `
    left: ${props.left}px;
  `}
`;
