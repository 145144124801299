import { WeekDaysNumbers, weekendsOnWorkDays, workDaysOnWeekends } from "@constants/calendar";
import { IColors } from "@interfaces/theme.interface";

export function memoize<T extends (...args: unknown[]) => ReturnType<T>>(func: T): T {
  const cache: { [key: string]: ReturnType<T> } = {};

  return function (...args: Parameters<T>): ReturnType<T> {
    const key = JSON.stringify(args);

    if (!(key in cache)) {
      cache[key] = func(...args);
    }

    return cache[key];
  } as T;
}

export const memoizedDayColor = memoize(
  (
    weekDay: number | undefined,
    isTodayApp: boolean,
    isTodayReal: boolean,
    isNotCurrentMonth: boolean,
    dayForEvents: string,
    theme: IColors,
  ) => {
    if (isTodayApp || isTodayReal) return "#fff";

    if (weekendsOnWorkDays.includes(dayForEvents)) {
      return isNotCurrentMonth ? "#FF3B3080" : "#FF3B30";
    }

    if ((weekDay === WeekDaysNumbers.SATURDAY || weekDay === WeekDaysNumbers.SUNDAY) && !workDaysOnWeekends.includes(dayForEvents)) {
      return isNotCurrentMonth ? "#FF3B3080" : "#FF3B30";
    }

    if (
      weekendsOnWorkDays.includes(dayForEvents) ||
      (weekDay !== undefined && [WeekDaysNumbers.SATURDAY, WeekDaysNumbers.SUNDAY].includes(weekDay))
    ) {
      if (workDaysOnWeekends.includes(dayForEvents)) {
        return isNotCurrentMonth ? `${theme.text.main}80` : theme.text.main;
      }

      return isNotCurrentMonth ? "#FF3B3080" : "#FF3B30";
    }

    return isNotCurrentMonth ? "#27433580" : theme.text.main;
  },
);
