export const MONDAY = "понедельник";
export const TUESDAY = "вторник";
export const WEDNESDAY = "среда";
export const THURSDAY = "четверг";
export const FRIDAY = "пятница";
export const SATURDAY = "суббота";
export const SUNDAY = "воскресенье";

export enum WeekDaysFullLabels {
  MONDAY = "понедельник",
  TUESDAY = "вторник",
  WEDNESDAY = "среда",
  THURSDAY = "четверг",
  FRIDAY = "пятница",
  SATURDAY = "суббота",
  SUNDAY = "воскресенье",
}

export enum WeekDaysShortLabels {
  MONDAY = "пн",
  TUESDAY = "вт",
  WEDNESDAY = "ср",
  THURSDAY = "чт",
  FRIDAY = "пт",
  SATURDAY = "сб",
  SUNDAY = "вс",
}

export enum WeekDaysNumbers {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 0,
}

export enum WeekDaysNumbersRU {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export const workDaysOnWeekends = ["2024-04-27", "2024-11-02", "2024-12-28", "2025-11-01"];

export const weekendsOnWorkDays = [
  "2025-01-01",
  "2025-01-02",
  "2025-01-03",
  "2025-01-06",
  "2025-01-07",
  "2025-01-08",
  "2025-05-01",
  "2025-05-02",
  "2025-05-08",
  "2025-05-09",
  "2025-06-12",
  "2025-06-13",
  "2025-11-03",
  "2025-11-04",
  "2025-12-31",

  "2024-02-23",
  "2024-03-08",
  "2024-04-29",
  "2024-04-30",
  "2024-05-01",
  "2024-05-09",
  "2024-05-10",
  "2024-06-12",
  "2024-11-04",
  "2024-12-30",
  "2024-12-31",
  "2024-01-01",
  "2024-01-02",
  "2024-01-03",
  "2024-01-04",
  "2024-01-05",
  "2024-01-08",
];

export enum Times {
  OneMin = 60,
  Mins_2 = 120,
  Mins_5 = 300,
  Mins_30 = 1800,
}
