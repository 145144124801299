import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import dayjs from "dayjs";
import { unionBy } from "lodash";

import { ICategory } from "@components/screens/day/components/timetable/main/modal/components/viewNotSave/types";
import { Times } from "@constants/calendar";
import { IComment } from "@interfaces/businessGoals.interface";
import { IEventOfDaySimplified, IParticipantAttendance } from "@interfaces/eventsOfDay.interface";
import {
  IAddParticipantParams,
  IListMeetingRooms,
  IMeet,
  IOccupancyRoom,
  ITimeTableMeet,
  IUnansweredMeetList,
  IUpdateParticipantsPermissionsDTO,
  TTypeMeetingStatus,
} from "@interfaces/meet.interface";
import { IDisableNotifications } from "@interfaces/userSettings.interface";
import { TPeriodToLoadCalendar } from "@store/calendar/slice";
import { setCurrentEvent, updateCurrentEvent, markParticipantStatusAsViewed, setEventToReopen, updateId } from "@store/screenDay/slice";
import { showToast, toFormatDate } from "@utils";

import { StoreTagTypes, api } from "./api";
import { IAttendanceDTO, ICommonSlotDTO, IOccupancyDTO, ISetCommentParams } from "./meet.service";

interface IUpdateSingleMeetingParams {
  id: string;
  repeat: boolean;
  date: string;
  data: Partial<IMeet>;
  isRemoveFromList?: boolean;
  changeSerie?: boolean;
  parentEvent?: IMeet | null;
  skipOptimisticUpdate?: boolean;
  isMouseRightClick?: boolean; // for context menu click
  period?: TPeriodToLoadCalendar;
  startTime?: string;
}

const removeMeetingFromCalendarByIdId = (obj: Record<string, IEventOfDaySimplified[]>, targetId: string, startTime?: string) => {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key] = obj[key].filter((item) => (startTime ? item.id !== targetId && item.startTime !== startTime : item.id !== targetId));
    }
  }

  return obj;
};

export const meetApi = api.injectEndpoints({
  endpoints: (build) => ({
    // build.query - только для не мутирующих действий.
    // <arg1, arg2> - arg1 - тип ответа. arg2 - входящие параметры
    getMeetingById: build.query<IMeet, { id: string; repeat: boolean; currentDate: string }>({
      query: ({ id, repeat, currentDate }) => {
        if (!id) return null;

        const adjustedURL = repeat
          ? `/api/api-gateway/v1/meetings/v1/meetings/${id}/date/${currentDate}`
          : `/api/api-gateway/v1/meetings/${id}`;

        return {
          url: adjustedURL,
          method: "get",
        };
      },
      transformResponse: (response: IMeet) =>
        ({
          ...response,
          participants: response?.participants?.filter((i) => !i.user.roles.includes("SERVICE_USER")),
        } as IMeet),

      providesTags: (_result, _error, arg) => [{ type: StoreTagTypes.Meet, id: arg.id }], // уникальный тег Meet/${id}
      keepUnusedDataFor: Times.Mins_5,
    }),

    getMeetingList: build.query<IEventOfDaySimplified[], { date: string }>({
      query: ({ date }) => ({
        url: `/api/api-gateway/v1/events/date/${date}`,
        method: "GET",
      }),
      transformResponse: (response: IEventOfDaySimplified[]) => response?.map((i) => ({ ...i, deep: 0 })),
      providesTags: () =>
        // общий 'абстрактный' тег Meet/getMeetingList/${date}/LIST для списка событий.
        // Сбросив его можно обнулить все ранее подгруженные дни
        [{ type: StoreTagTypes.Meet, id: "LIST" }],
    }),
    createMeeting: build.mutation<IMeet, { data: IMeet; date: string }>({
      query: ({ data }) => ({
        url: "/api/calendar/v1/meetings",
        method: "POST",
        data,
      }),
      async onQueryStarted({ date, data }, { dispatch, queryFulfilled }) {
        const changedProps = Object.keys(data) ?? [];
        const isSameDay = dayjs(date).isSame(dayjs(data?.startTime ?? ""), "day");
        const isRepeatedEventFitsCurrentDay = changedProps.includes("repeat") && changedProps.includes("startTime") && isSameDay;

        try {
          // Pessimistic updates. Сначала ждем ответ от бэка...
          const { data: createdMeeting } = await queryFulfilled;

          if ((!data.repeat && isSameDay) || isRepeatedEventFitsCurrentDay) {
            // ...и только потом добавляем встречу в ртк кэш на выбранный день, исключаем рефетч
            dispatch(
              api.util.updateQueryData("getMeetingList", { date: date }, (draft: ITimeTableMeet[]) => {
                draft.push({ ...createdMeeting, deep: 0 });
              }),
            );
          }

          if (data.repeat) {
            // При добавлении встреч с повтором сбрасываем кэш 'LIST' для всех дней.
            dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));
          }
        } catch (e) {
          console.error(e);
        }
      },
      invalidatesTags: [StoreTagTypes.Calendar],
    }),
    deleteSingleOrExceptionMeeting: build.mutation<
      void,
      { id: string; date: string; isException: boolean; calendarPeriodStart: string; calendarPeriodEnd: string }
    >({
      query: ({ id, date, isException }) => ({
        url: `/api/calendar/v1/meetings/${id}${isException ? `/date/${date}` : ""}`,
        method: "DELETE",
      }),
      async onQueryStarted({ id, date, calendarPeriodStart, calendarPeriodEnd }, { dispatch, queryFulfilled }) {
        // Optimistic updates. Применяем изменения немедленно.
        const patchMeetingList = dispatch(
          api.util.updateQueryData("getMeetingList", { date: date }, (draft: ITimeTableMeet[]) => draft.filter((meet) => meet.id !== id)),
        );

        const patchCalendar = dispatch(
          api.util.updateQueryData(
            "getCalendarEventsList",
            { startDate: calendarPeriodStart, endDate: calendarPeriodEnd },
            (draft: Record<string, IEventOfDaySimplified[]>) => removeMeetingFromCalendarByIdId(draft, id),
          ),
        );

        try {
          await queryFulfilled; // Ждем результат запроса
        } catch (e) {
          patchMeetingList.undo(); // запрос упал, откатываем изменения
          patchCalendar.undo();
        }
      },
    }),
    deleteMeetingSerie: build.mutation<null, { id: string; date: string; calendarPeriodStart: string; calendarPeriodEnd: string }>({
      query: ({ id }) => ({
        url: `/api/calendar/v1/meetings/series/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted({ id, date, calendarPeriodStart, calendarPeriodEnd }, { dispatch, queryFulfilled }) {
        const patchMeetingList = dispatch(
          api.util.updateQueryData("getMeetingList", { date: date }, (draft: ITimeTableMeet[]) => draft.filter((meet) => meet.id !== id)),
        );

        const patchCalendar = dispatch(
          api.util.updateQueryData(
            "getCalendarEventsList",
            { startDate: calendarPeriodStart, endDate: calendarPeriodEnd },
            (draft: Record<string, IEventOfDaySimplified[]>) => removeMeetingFromCalendarByIdId(draft, id),
          ),
        );

        try {
          await queryFulfilled;
        } catch (e) {
          patchMeetingList.undo();
          patchCalendar.undo();
        }
      },
      invalidatesTags: [{ type: StoreTagTypes.Meet, id: "LIST" }],
    }),

    updateSingleOrSerieMeeting: build.mutation<IMeet, IUpdateSingleMeetingParams>({
      query: ({ id, repeat, date, data, changeSerie, parentEvent }) => {
        if (!id) return null;

        const isSingleEvent = !repeat && !parentEvent;
        const isExceptionEventFromSeries = !repeat && Boolean(parentEvent);
        const isSerie = !!repeat;

        const adjustedURL =
          isSingleEvent || isExceptionEventFromSeries || (changeSerie && isSerie)
            ? `/api/api-gateway/v1/meetings/series/${id}`
            : `/api/api-gateway/v1/meetings/${id}/date/${date}`;

        return {
          url: adjustedURL,
          method: "PATCH",
          data,
        };
      },
      async onQueryStarted(
        { id, data, date, repeat, isRemoveFromList, changeSerie, skipOptimisticUpdate = false, isMouseRightClick, period, startTime },
        { dispatch, queryFulfilled },
      ) {
        const changedProps = Object.keys(data) ?? [];
        const isResetTimetableCaches = changedProps.includes("repeat") || changedProps.includes("active") || !repeat;
        const isMeetingMoved = changedProps.includes("startTime") && !dayjs(date).isSame(dayjs(data?.startTime ?? ""), "day");
        let patchCurrentDay: PatchCollection | undefined = undefined;
        let getMeetingByIdPatch: PatchCollection | undefined = undefined;

        // Optimistic update. Убираем встречу из расписания немедленно.
        if (isMeetingMoved || isRemoveFromList || isMouseRightClick) {
          patchCurrentDay = dispatch(
            api.util.updateQueryData("getMeetingList", { date: date }, (draft: ITimeTableMeet[]) => draft.filter((meet) => meet.id !== id)),
          );
        }

        const { data: response } = await queryFulfilled;

        if (!skipOptimisticUpdate) {
          getMeetingByIdPatch = dispatch(
            api.util.updateQueryData(
              "getMeetingById",
              { id: response.id, repeat: !!response.repeat, currentDate: toFormatDate(dayjs(response.startTime)) },
              () => response,
            ),
          );
        }

        const patchCalendar = dispatch(
          api.util.updateQueryData(
            "getCalendarEventsList",
            { startDate: period?.startDate, endDate: period?.endDate },
            (draft: Record<string, IEventOfDaySimplified[]>) => removeMeetingFromCalendarByIdId(draft, id, startTime),
          ),
        );

        try {
          if (id !== response.id) {
            !isMouseRightClick &&
              dispatch(
                setEventToReopen({
                  id: response.id,
                  repeat: !!response.repeat,
                  type: "MEET",
                  date: changeSerie ? undefined : response.startTime ?? "",
                }),
              );

            !isMouseRightClick && dispatch(updateId({ id: response.id, modalOpen: true }));
            dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));

            return;
          }

          if (isResetTimetableCaches) {
            dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));
          }

          !isMouseRightClick && dispatch(setCurrentEvent(response));

          if (!isMeetingMoved && !isRemoveFromList) {
            dispatch(
              api.util.updateQueryData("getMeetingList", { date: date }, (draft: ITimeTableMeet[]) =>
                draft.forEach((item) => {
                  if (item.id === id) {
                    Object.assign(item, response);
                  }
                }),
              ),
            );
          }

          if (changeSerie) {
            dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet }]));
          }
        } catch {
          patchCurrentDay?.undo();
          getMeetingByIdPatch?.undo();
          patchCalendar.undo();
          dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));
        }
      },
    }),

    getListMeetingRooms: build.query<IListMeetingRooms[], undefined>({
      query: () => ({
        url: "/api/settings/v1/meeting-rooms",
        method: "GET",
      }),
      keepUnusedDataFor: Times.Mins_2,
    }),
    getMeetingAttendance: build.query<IParticipantAttendance[], IAttendanceDTO>({
      query: ({ startTime, endTime, userIds, excludedMeetingId }) => {
        if (!userIds?.length || !startTime || !endTime) return null;

        const exludeMeetingIdParam = excludedMeetingId ? `&excludedMeetingId=${excludedMeetingId}` : "";

        return {
          url: `/api/calendar/v1/meetings/attendance?startTime=${startTime}&endTime=${endTime}&userIds=${userIds}${exludeMeetingIdParam}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
    }),

    getOccupancyRooms: build.query<IOccupancyRoom[], IOccupancyDTO>({
      query: ({ startTime, endTime, externalIds }) => {
        if (!externalIds?.length || !startTime || !endTime) return null;

        return {
          url: `/api/api-gateway/v1/meeting-rooms/attendance?startTime=${startTime}&endTime=${endTime}&externalIds=${externalIds}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
    }),

    getCommonMeetingSlot: build.query<{ startTime: string; endTime: string }, { data: ICommonSlotDTO }>({
      query: ({ data }) => ({
        url: `/api/calendar/v1/meetings/common-slot?${Object.entries(data)
          .map(([key, value]) => `${key}=${value}`)
          .join("&")}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    setParticipantStatus: build.mutation<void, ISetCommentParams>({
      query: ({ id, status, comment, date }) => {
        const dateParam = date ? `/date/${date}` : "";

        return {
          url: `/api/calendar/v1/meetings/${id}${dateParam}/participants/status/${status}`,
          method: "POST",
          data: { comment },
        };
      },
      async onQueryStarted({ id, status, comment, selectedDay, participantId, period, date }, { dispatch, queryFulfilled }) {
        const patchMeetingList = dispatch(
          api.util.updateQueryData("getMeetingList", { date: selectedDay }, (draft: ITimeTableMeet[]) => {
            if (status === "NON_ATTENDER") {
              return draft.filter((meet) => meet.id !== id);
            }
            return draft.forEach((item) => {
              if (item.id === id) {
                Object.assign(item, { currentParticipantStatus: status });
              }
            });
          }),
        );

        const patchedUnansweredMeets = dispatch(
          api.util.updateQueryData("getUnansweredList", { name: "" }, (draft: IUnansweredMeetList[]) =>
            draft.map((i) => {
              if (i.id === id) return { ...i, meetingStatus: status };
              return i;
            }),
          ),
        );
        const patchMeetingListCalendar = dispatch(
          api.util.updateQueryData(
            "getCalendarEventsList",
            { startDate: period?.startDate, endDate: period?.endDate },
            (draft: Record<string, IEventOfDaySimplified[]>) => {
              const mapDaysOfPeriod = Object.keys(draft) ?? [];
              if (date) {
                return draft[date]?.forEach((item) => {
                  if (item.id === id) {
                    Object.assign(item, { currentParticipantStatus: status });
                  }
                });
              }
              mapDaysOfPeriod.forEach((day) =>
                draft[day]?.forEach((item) => {
                  if (item.id === id) {
                    Object.assign(item, { currentParticipantStatus: status });
                  }
                }),
              );
            },
          ),
        );

        try {
          await queryFulfilled;

          dispatch(
            updateCurrentEvent({
              updation: {
                currentParticipantStatus: status,
              },
              meta: {
                participantId,
                comment,
              },
            }),
          );
          dispatch(
            api.util.invalidateTags([
              { type: StoreTagTypes.Meet, id: id },
              { type: StoreTagTypes.Meet, id: "UNANSWERED_COUNT" },
            ]),
          );
        } catch {
          patchMeetingList.undo();
          patchedUnansweredMeets.undo();
          patchMeetingListCalendar.undo();
          dispatch(
            api.util.invalidateTags([
              { type: StoreTagTypes.Meet, id: id },
              { type: StoreTagTypes.Meet, id: "LIST" },
            ]),
          );
        }
      },
    }),
    setParticipantStatusCommentAsViewed: build.mutation<void, { id: string; date: string; userId: string; repeat: boolean }>({
      query: ({ id, date, userId }) => ({
        url: `/api/calendar/v1/meetings/${id}/date/${date}/participants/${userId}/status/comments/view`,
        method: "POST",
      }),
      async onQueryStarted({ userId, id, date, repeat }, { dispatch, queryFulfilled }) {
        const patchedMeet = dispatch(
          api.util.updateQueryData("getMeetingById", { id, repeat, currentDate: date }, (draft: IMeet) => {
            draft.participants?.forEach((item) => {
              if (item.userId === userId) {
                Object.assign(item, { statusCommentIsViewed: true });
              }
            });
          }),
        );

        try {
          await queryFulfilled;
          dispatch(markParticipantStatusAsViewed({ userId }));
        } catch (error) {
          patchedMeet.undo();
        }
      },
    }),
    setDisableMeetingNotification: build.mutation<void, { id: string; data: IDisableNotifications[] }>({
      query: ({ id, data }) => {
        if (!id || !data) return null;

        return {
          url: `/api/calendar/v1/meetings/${id}/notification/disable-settings`,
          method: "PUT",
          data,
        };
      },
      async onQueryStarted({ data }, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          updateCurrentEvent({
            updation: {
              notificationDisableSettings: data,
            },
          }),
        );
      },
      invalidatesTags: (_result, _error, arg) => [{ type: StoreTagTypes.Meet, id: arg.id }],
    }),
    addMeetingComment: build.mutation<IComment, { eventId: string; comment: string }>({
      query({ eventId, comment }) {
        return {
          url: `/api/calendar/v1/meetings/${eventId}/comments`,
          method: "POST",
          data: { comment },
        };
      },
      invalidatesTags: (_result, _error, arg) => [{ type: StoreTagTypes.Meet, id: arg.eventId }],
    }),
    editMeetingComment: build.mutation<IComment, { eventId: string; commentId: string; comment: string }>({
      query: ({ eventId, commentId, comment }) => ({
        url: `/api/calendar/v1/meetings/${eventId}/comments/${commentId}`,
        method: "PATCH",
        data: { comment },
      }),
      invalidatesTags: (_result, _error, arg) => [{ type: StoreTagTypes.Meet, id: arg.eventId }],
    }),
    deleteMeetingComment: build.mutation<void, { eventId: string; commentId: string }>({
      query: ({ eventId, commentId }) => {
        if (!commentId) return null;

        return {
          url: `/api/calendar/v1/meetings/${eventId}/comments/${commentId}`,
          method: "DELETE",
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {
          showToast("deleteAlert", "error");
        }
      },
      invalidatesTags: (_result, _error, arg) => [{ type: StoreTagTypes.Meet, id: arg.eventId }],
    }),
    updateMeetingStatus: build.mutation<void, { id: string; date: string; type: TTypeMeetingStatus; period?: TPeriodToLoadCalendar }>({
      query: ({ id, date, type }) => ({
        url: `/api/calendar/v1/meetings/${id}/date/${date}/participants/meeting-status/${type}`,
        method: "PATCH",
      }),
      async onQueryStarted({ id, date, type, period }, { dispatch, queryFulfilled }) {
        const patchTimetable = dispatch(
          api.util.updateQueryData("getMeetingList", { date: date }, (draft: ITimeTableMeet[]) =>
            draft.forEach((item) => {
              if (item.id === id) {
                Object.assign(item, { currentParticipantMeetingStatus: type });
              }
            }),
          ),
        );

        const patchCalendar = dispatch(
          api.util.updateQueryData(
            "getCalendarEventsList",
            { startDate: period?.startDate, endDate: period?.endDate },
            (draft: Record<string, IEventOfDaySimplified[]>) =>
              draft[date]?.forEach((item) => {
                if (item.id === id) {
                  Object.assign(item, { currentParticipantMeetingStatus: type });
                }
              }),
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchTimetable.undo();
          patchCalendar.undo();
        }
      },
      invalidatesTags: (_result, _error, arg) => [{ type: StoreTagTypes.Meet, id: arg.id }],
    }),

    acceptOwnerStatus: build.mutation<IMeet, { id: string; userId: string; isSerie: boolean; isException: boolean }>({
      query: ({ id, userId }) => ({
        url: `/api/calendar/v1/meetings/${id}/accept-author-and-owner-status?userId=${userId}`,
        method: "PATCH",
      }),
      async onQueryStarted({ id, isSerie, isException }, { dispatch, queryFulfilled }) {
        const { data: response } = await queryFulfilled;
        if (response?.id && id !== response?.id && !isException) {
          dispatch(setEventToReopen({ id: response?.id, type: "MEET", repeat: !!response.repeat, date: response.startTime }));
          dispatch(updateId({ id: response.id, modalOpen: true }));
        } else {
          if (isSerie) {
            dispatch(api.util.invalidateTags([StoreTagTypes.Meet]));
          } else {
            dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id }]));
          }
        }
      },
      invalidatesTags: () => [{ type: StoreTagTypes.Meet, id: "LIST" }],
    }),

    changeAuthor: build.mutation<IMeet, { id: string; userId: string; isSerie: boolean; isException: boolean }>({
      query: ({ id, userId }) => ({
        url: `/api/calendar/v1/meetings/${id}/assign-owner-status-to/${userId}`,
        method: "PATCH",
      }),
      async onQueryStarted({ id, isSerie, isException }, { dispatch, queryFulfilled }) {
        const { data: response } = await queryFulfilled;
        if (response?.id && id !== response?.id && !isException) {
          dispatch(setEventToReopen({ id: response?.id, type: "MEET", repeat: !!response.repeat, date: response.startTime }));
          dispatch(updateId({ id: response.id, modalOpen: true }));
        } else {
          if (isSerie) {
            // Сбрасываем все, т.к. у родителя нет ссылки на исключение
            // Определить id исключения для точечного сброса нельзя
            dispatch(api.util.invalidateTags([StoreTagTypes.Meet]));
          } else {
            dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id }]));
          }
        }

        if (isException) {
          dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: response?.id }]));
        }

        if (isException) {
          dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: response?.id }]));
        }
      },
      invalidatesTags: () => [{ type: StoreTagTypes.Meet, id: "LIST" }],
    }),

    removeMeetingStatus: build.mutation<void, { id: string; date: string; period: TPeriodToLoadCalendar }>({
      query: ({ id, date }) => ({
        url: `/api/calendar/v1/meetings/${id}/date/${date}/participants/meeting-status`,
        method: "DELETE",
      }),
      async onQueryStarted({ id, date, period }, { dispatch, queryFulfilled }) {
        const patchTimetable = dispatch(
          api.util.updateQueryData("getMeetingList", { date: date }, (draft: ITimeTableMeet[]) =>
            draft.forEach((item) => {
              if (item.id === id) {
                Object.assign(item, { currentParticipantMeetingStatus: null });
              }
            }),
          ),
        );

        const patchCalendar = dispatch(
          api.util.updateQueryData(
            "getCalendarEventsList",
            { startDate: period?.startDate, endDate: period?.endDate },
            (draft: Record<string, IEventOfDaySimplified[]>) =>
              draft[date].forEach((item) => {
                if (item.id === id) {
                  Object.assign(item, { currentParticipantMeetingStatus: null });
                }
              }),
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchTimetable.undo();
          patchCalendar.undo();
        }
      },
      invalidatesTags: (_result, _error, arg) => [{ type: StoreTagTypes.Meet, id: arg.id }],
    }),
    removeFromShedule: build.mutation<
      void,
      { id: string; date: string; repeat: boolean; period: TPeriodToLoadCalendar; startTime: string }
    >({
      query: ({ id }) => ({
        url: `/api/calendar/v1/meetings/${id}/canceled/remove-from-schedule`,
        method: "PATCH",
      }),
      async onQueryStarted({ id, date, repeat, period, startTime }, { dispatch, queryFulfilled }) {
        const patchTimetable = dispatch(
          api.util.updateQueryData("getMeetingList", { date: date }, (draft: ITimeTableMeet[]) => draft.filter((meet) => meet.id !== id)),
        );

        const patchCalendar = dispatch(
          api.util.updateQueryData(
            "getCalendarEventsList",
            { startDate: period?.startDate, endDate: period?.endDate },
            (draft: Record<string, IEventOfDaySimplified[]>) => removeMeetingFromCalendarByIdId(draft, id, startTime),
          ),
        );

        try {
          await queryFulfilled;

          if (repeat) {
            dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: "LIST" }]));
          }
        } catch (e) {
          patchTimetable.undo();
          patchCalendar.undo();
        }
      },
    }),
    getAvailablePlaces: build.query<string[], void>({
      query: () => ({
        url: "/api/calendar/v1/meetings/places/available",
        method: "GET",
      }),
      keepUnusedDataFor: Times.Mins_30,
    }),
    getUnansweredCount: build.query<number, void>({
      query: () => ({
        url: "/api/calendar/v1/meetings/unanswered-count",
        method: "GET",
      }),
      keepUnusedDataFor: Times.Mins_2,
      providesTags: () => [{ type: StoreTagTypes.Meet, id: "UNANSWERED_COUNT" }],
    }),
    getUnansweredList: build.query<IUnansweredMeetList[], { name: string }>({
      query: ({ name }) => ({
        url: `/api/calendar/v1/meetings/unanswered?name=${name}`,
        method: "GET",
      }),
      keepUnusedDataFor: Times.Mins_2,
      providesTags: () => [StoreTagTypes.UnansweredMeets],
    }),
    updateParticipantsPermissions: build.mutation<void, { id: string; data: IUpdateParticipantsPermissionsDTO[]; userId?: string }>({
      query: ({ id, data, userId }) => {
        const directorIdParam = userId ? `?userId=${userId}` : "";

        return {
          url: `/api/calendar/v1/meetings/${id}/participants/permissions${directorIdParam}`,
          method: "PUT",
          data,
        };
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        /* setTimeout т.к. в данном случае инвалидация не тригерит рефетч встречи */
        setTimeout(() => {
          dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: id }]));
        }, 100);
      },
    }),
    addParticipantsToSingleOrSerieMeeting: build.mutation<IMeet, IAddParticipantParams>({
      query: ({ id, data, repeat, parentEvent, date, changeSerie, userId }) => {
        const isSingleEvent = !repeat && !parentEvent;
        const isExceptionEventFromSeries = !repeat && Boolean(parentEvent);

        const directorIdParam = userId ? `?userId=${userId}` : "";

        let adjustedUrl = `/api/calendar/v1/meetings/${id}/date/${date}/participants${directorIdParam}`;

        if (changeSerie || isExceptionEventFromSeries || isSingleEvent) {
          adjustedUrl = `/api/calendar/v1/meetings/series/${id}/participants${directorIdParam}`;
        }

        return {
          url: adjustedUrl,
          method: "patch",
          data,
        };
      },
      async onQueryStarted({ id, changeSerie, date, repeat }, { dispatch, queryFulfilled }) {
        const isSerie = !!repeat;

        try {
          const { data: response } = await queryFulfilled;

          // Если делаем исключение из серии, то просто обновляем кэш дня без доп запроса.
          if (!changeSerie && isSerie) {
            dispatch(
              api.util.updateQueryData("getMeetingList", { date: date }, (draft: ITimeTableMeet[]) =>
                draft.forEach((item) => {
                  if (item.id === id) {
                    Object.assign(item, response);
                  }
                }),
              ),
            );
          }

          if (id === response.id) {
            dispatch(api.endpoints.getMeetingById.initiate({ id: response.id, repeat: false, currentDate: date }));
            dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet }]));
          } else {
            /*
              При попытке изменить старую серию бэк на запрос с id:A возвращает id:B (!!!)
              Модалка и ртк кэш остаются подвязаны на id:A.
              Делаем искусственное "переоткрытие" модалки с id:B
            */
            dispatch(setEventToReopen({ id: response?.id, type: "MEET", repeat: !!response.repeat, date: response.startTime }));
            dispatch(updateId({ id: response.id, modalOpen: true }));

            setTimeout(() => {
              dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet }]));
            }, 200);
          }
        } catch {
          dispatch(api.util.invalidateTags([StoreTagTypes.Meet]));
        }
      },
    }),
    deleteExternalParticipant: build.mutation<IMeet, { externalUserId: string; meetId?: string; isMeet: boolean; disableUpdate?: boolean }>(
      {
        query: ({ meetId, externalUserId, isMeet }) => ({
          url:
            isMeet && meetId
              ? `/api/calendar/v1/meetings/${meetId}/external-participants/${externalUserId}`
              : `/api/calendar/v1/external-users/${externalUserId}`,
          method: "delete",
        }),

        async onQueryStarted({ meetId, isMeet, disableUpdate }, { dispatch, queryFulfilled }) {
          if (!isMeet) return;

          try {
            const { data: response } = await queryFulfilled;

            setTimeout(() => {
              dispatch(api.util.invalidateTags([StoreTagTypes.ExternalUser]));
            }, 0);

            if (meetId === response.id) {
              if (disableUpdate) return;
              dispatch(api.util.invalidateTags([{ type: StoreTagTypes.Meet, id: response.id }]));
            } else {
              dispatch(setEventToReopen({ id: response?.id, type: "MEET", repeat: !!response.repeat, date: response.startTime }));
              dispatch(updateId({ id: response.id, modalOpen: true }));
            }
          } catch (error) {
            showToast("somethingWentWrong", "error");
          }
        },

        invalidatesTags: (_result, _error, arg) => [
          { type: StoreTagTypes.ExternalUser, id: arg.externalUserId },
          { type: StoreTagTypes.Meet, id: "LIST" },
          { type: StoreTagTypes.MixedUsers },
        ],
      },
    ),
    getMeetCategoryList: build.query<ICategory[], void>({
      query: () => ({
        url: "/api/calendar/v1/categories",
        method: "GET",
      }),
    }),
    updateMeetCategoryList: build.mutation<void, ICategory[]>({
      query: (data) => ({
        url: "/api/calendar/v1/categories",
        method: "PATCH",
        data,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const response = await queryFulfilled;
        const updatedCategories = response?.data as unknown as ICategory[];
        const patchResult = dispatch(
          meetApi.util.updateQueryData("getMeetCategoryList", undefined, (draft) => {
            const mergedCategories = unionBy(updatedCategories, draft, "color");
            return mergedCategories;
          }),
        );

        try {
          await queryFulfilled;
        } catch (e) {
          patchResult.undo();
        }
      },
    }),
    deleteMeetCategoryList: build.mutation<void, { ids: string[] }>({
      query: (data) => ({
        url: `/api/calendar/v1/categories?categoryIds=${data.ids.join(",")}`,
        method: "DELETE",
      }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          meetApi.util.updateQueryData("getMeetCategoryList", undefined, (draft) => {
            const filtredList = draft.filter((category) => !data.ids.some((id) => id === category.id));
            return filtredList;
          }),
        );

        try {
          await queryFulfilled;
        } catch (e) {
          patchResult.undo();
        }
      },
    }),
    updateMeetCategory: build.mutation<void, { id: string; date?: string; categoryId: string }>({
      query: ({ id, date: date, categoryId }) => ({
        url: date
          ? `/api/calendar/v1/meetings/${id}/date/${date}/participants/meeting-category/${categoryId ?? ""}`
          : `/api/calendar/v1/meetings-series/${id}/participants/meeting-category/${categoryId ?? ""}`,
        method: categoryId ? "patch" : "delete",
      }),
      invalidatesTags: (_result, _error, arg) => [
        { type: StoreTagTypes.Meet, id: arg.id },
        { type: StoreTagTypes.Meet, id: "LIST" },
        { type: StoreTagTypes.Calendar },
      ],
    }),
  }),
});

export const {
  useGetMeetingByIdQuery,
  useGetMeetingListQuery,
  useCreateMeetingMutation,
  useDeleteSingleOrExceptionMeetingMutation,
  useDeleteMeetingSerieMutation,
  useUpdateSingleOrSerieMeetingMutation,
  useGetMeetingAttendanceQuery,
  useGetCommonMeetingSlotQuery,
  useSetParticipantStatusMutation,
  useSetParticipantStatusCommentAsViewedMutation,
  useSetDisableMeetingNotificationMutation,
  useAddMeetingCommentMutation,
  useEditMeetingCommentMutation,
  useDeleteMeetingCommentMutation,
  useUpdateMeetingStatusMutation,
  useRemoveMeetingStatusMutation,
  useRemoveFromSheduleMutation,
  useGetAvailablePlacesQuery,
  useGetUnansweredCountQuery,
  useGetUnansweredListQuery,
  useUpdateParticipantsPermissionsMutation,
  useAddParticipantsToSingleOrSerieMeetingMutation,
  useAcceptOwnerStatusMutation,
  useChangeAuthorMutation,
  useGetListMeetingRoomsQuery,
  useGetOccupancyRoomsQuery,
  useDeleteExternalParticipantMutation,
  useDeleteMeetCategoryListMutation,
  useGetMeetCategoryListQuery,
  useUpdateMeetCategoryListMutation,
  useUpdateMeetCategoryMutation,
} = meetApi;
